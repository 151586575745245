import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Seo from "../../components/seo";
import Layout from "../../components/layout";
import MemberDetailProfile from "./member-detail-profile";
import MemberDetailWorks from "./member-detail-works";

const maxWorksCount = 6;

const MemberDetail = ({ location, primaryWorks, profileContent, awardsContent, ogpImage }) => {
  const { worksJson, membersJson } = useStaticQuery(
    graphql`
      query {
        worksJson: allWorksJson {
          edges {
            node {
              category
              client
              imagePosition
              members
              slug
              title
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 960)
                }
              }
              id
            }
          }
        }
        membersJson: allMembersJson {
          edges {
            node {
              slug
              name
              en_name
              category
              imagePosition
              title
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1280)
                }
              }
            }
          }
        }
      }
    `
  );

  const slug = location.pathname.split('/')[2];
  const memberInfo = membersJson.edges.find((edge) => edge.node.slug === slug).node;
  const memberWorks = (primaryWorks.length === 0)
                      ? worksJson.edges.filter((edge) => edge.node.members.includes(slug)).slice(0, maxWorksCount)
                      : worksJson.edges.filter((edge) => primaryWorks.includes(edge.node.slug));

  return (
    <React.Fragment>
      <Seo title={`${memberInfo.name} (${memberInfo.en_name})`} pageClass="memberDetailPage" ogpImage={ogpImage} />
      <Layout>
        <MemberDetailProfile info={memberInfo}>
          {profileContent()}
        </MemberDetailProfile>
        {awardsContent()}
        <MemberDetailWorks works={memberWorks} />
      </Layout>
    </React.Fragment>
  );
};

export default MemberDetail;
