import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useToggleClassTrigger from "../transitions/use-toggle-class-trigger";

const WorksEntry = ({ node, showCategory }) => {
  const path = `/works/${node.slug}/`;
  const image = node.image.childImageSharp.gatsbyImageData;

  const { targetRef } = useToggleClassTrigger("entered", "top 90%");

  return (
    <Link to={path} className="list col-lg-4 col-sm-6 col-12 worksEntry" ref={targetRef}>
      { showCategory && <h4>{node.category}</h4> }
      <div className="imageOuter">
        <GatsbyImage image={image} alt="" objectPosition={node.imagePosition} />
      </div>
      <h2 dangerouslySetInnerHTML={{__html: node.title}} />
      <h3 dangerouslySetInnerHTML={{__html: node.client}} />
    </Link>
  );
};

WorksEntry.defaultProps = {
  node: null,
  showCategory: true,
};

WorksEntry.propTypes = {
  node: PropTypes.object,
  showCategory: PropTypes.bool,
};

export default WorksEntry;
