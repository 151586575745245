import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        2023年入社。<br />
        東京大学大学院で地質学を学んだのち、カメラアシスタントのアルバイトを経て今に至る。<br />
        高校の先生が教えてくれた ”you have to trust that the dots will somehow connect in your future”という言葉がとても好き。<br />
        だが、果たして地質学の分野で取った修士号という"dot" はどこでつながってくるのだろうか。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
