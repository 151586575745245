import * as React from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const useToggleClassTrigger = (className, start) => {
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    const st = ScrollTrigger.create({
      trigger: targetRef.current,
      invalidateOnRefresh: true,
      endTrigger: "html",
      end: "bottom top",
      start: start,
      toggleClass: className
    });
    return (() => {
      st.kill();
    });
  }, [className, start]);

  return { targetRef };
};

export default useToggleClassTrigger;
