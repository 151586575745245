import * as React from "react";

const hiddenCategory = ['CHAIRMAN', 'CEO', 'DESK', 'ACCOUNTANT'];

const MemberDetailHead = ({ categories, title }) => {
  const category = categories[0];
  const showCategory = !(hiddenCategory.includes(category));

  return (
    <ul className="job">
      {showCategory &&
        <li>{category}</li>
      }
      <li>{title}</li>
    </ul>
  );
};

export default MemberDetailHead;
