import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import WorksEntry from "./works-entry";

const MemberDetailWorks = ({ works }) => {
  if (works.length === 0) {
    return null;
  }
  return (
    <Container fluid className="listContainer workRelated">
      <Row>
        <Col>
          <h3 className="titleTopBorder">Primary works</h3>
        </Col>
      </Row>
      <Row>
        {
          works.map(({ node }) => (
            <WorksEntry node={node} key={node.id} />
          ))
        }
      </Row>
    </Container>
  );
};

export default MemberDetailWorks;
