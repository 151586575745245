import * as React from "react";
import { Container } from 'react-bootstrap';
import MemberDetailHead from "./member-detail-head";
import MemberDetailImage from "./member-detail-image";

const MemberDetailProfile = ({ info, children }) => {
  return (
    <Container fluid className="memberProfile p-0">
      <div className="memberDetail">
        <MemberDetailHead categories={info.category} title={info.title} />
        <h1>{info.name}</h1>
        <span>{info.en_name}</span>
        {children}
      </div>
      <MemberDetailImage image={info.image} imagePosition={info.imagePosition} />
    </Container>
  );
};

export default MemberDetailProfile;
