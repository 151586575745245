import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const MemberDetailImage = ({ image, imagePosition }) => {
  return (
    <div className="memberImg">
      <div className="imageOuter">
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          objectPosition={imagePosition}
          layout="constrained"
          alt=""
        />
      </div>
    </div>
  );
};

export default MemberDetailImage;
